<template>
  <div class="header">
    <audio hidden="true" ref="audio">
      <source src="@/assets/media/music.mp3" type="audio/mpeg">
    </audio>
    <div v-if="!mobile" class="container">
      <div class="menu left-menu">
        <ul class="menu-list">
          <li class="mx-1">
            <button @click="toggleAudio()" ref="playbutton">
              <a v-if="playing" class="royal-link"><v-icon class="mdi mdi-pause royal-icon" /></a>
              <a v-if="!playing" class="royal-link"><v-icon class="mdi mdi-play royal-icon" /></a>

            </button>
          </li>
          <li class="mx-3">
            <a href="#main" class="royal-link">Главная</a>
          </li>
          <li class="mx-3"><a href="#service" class="royal-link">Услуги</a></li>
        </ul>
      </div>
      <div class="logo" :style="`width: ${buttonWidth}`">
        <transition name="fade" mode="out-in">
          <button @click="$emit('request')" class="header-button">
            <v-img class=" header-img" :src="icon" />
          </button>
        </transition>
      </div>
      <div class="menu right-menu">
        <ul class="menu-list">
          <li class="mx-3">
            <a href="#collection" class="royal-link">Коллекция</a>
          </li>
          <li class="mx-3">
            <a href="#conditions" class="royal-link">Условия</a>
          </li>
          <li class="mx-1">
            <a target="_blank" href="https://t.me/RoyalTravel32" class="royal-link">
              <telegram style="transform: scale(80%)" class="royal-icon" />
            </a>
            <v-icon>mdi-telegram</v-icon>
          </li>
          <li class="mx-1">
            <a target="_blank" href="https://api.whatsapp.com/send?phone=79308200909" class="royal-link"><v-icon
                class="mdi mdi-whatsapp royal-icon" /></a>
          </li>
          <li class="mx-1">
            <a target="_blank" href="tel:+79308200909" class="royal-link"><v-icon class="mdi mdi-phone royal-icon" /></a>
          </li>
        </ul>
      </div>
      <!-- <button>Оставить заявку</button> -->
    </div>
    <div v-else>
      <div class="row mobile-header justify-center">
        <div class="col col-9">
          <img class="icon-mobile" :src="iconMobile" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import iconMobile from "@/assets/img/icon-mobile.png";
import telegram from "@/assets/telegram";
import icon from "@/assets/img/icon.jpg";
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    telegram
  },
  data() {
    return {
      iconMobile,
      icon: icon,
      headerBg: false,
      requestButton: false,
      buttonWidth: "200px",
      playing: false,
      playingStarted: false,
      mobileMenu: false
    };
  },
  methods: {
    toggleAudio() {
      this.playing = !this.playing
      this.$refs.audio.volume = 0.3;
      if (this.playing) {
        this.$refs.audio.play()
      } else {
        this.$refs.audio.pause()
      }
    }
  },
  mounted() {
    setTimeout(3000, this.toggleAudio())
    window.onscroll = () => {
      if (window.pageYOffset) {
        this.headerBg = true;
        this.buttonWidth = "100px";
      } else {
        this.headerBg = false;
        this.buttonWidth = "200px";
      }
    };
  },
};
</script>

<style scoped>
.mobile-header {
  height: 100px;
}

.mobile-header .col {
  display: flex;
  justify-content: center;
  align-content: center;
}

.menu-icon {
  font-size: 50px;
  color: #d4b972;
}

.icon-mobile {
  max-width: 100%;
  height: auto;
  width: 100%;
  background-size: contain;
}

.mobile-menu-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80vw;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.3s ease-in;
}


.royal-icon {
  font-size: 20px;
  position: relative;
  color: #d4b972;
  bottom: 3px;
}

.header-button {
  max-height: 100%;
  width: 100%;
}

.logo {
  max-height: 100px;
  max-width: 200px;
  transition: 0.3s ease-in;
  margin: 0 80px;
}

.header-img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 100%;
}

.header {
  height: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: #0b4151;
}

.header::before {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  z-index: 0;
  transition: 0.5s ease-in-out;
}

.header.bg::before {
  height: 100%;
}

.header.bg * {
  color: gray;
}

.container {
  position: relative;
  z-index: 1;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
}

.menu-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 20px;
}


.royal-link {
  color: #d4b972;
  text-decoration: none;
  transition: 0.3s ease-in;
  text-transform: uppercase;
  position: relative;
  font-weight: bold;
}

.royal-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  height: 1px;
  background: #fff;
  transition: 0.3s ease-in;
}

.royal-link:hover::after {
  background: #f7c745;
  width: 100%;
  left: 0;
}

.royal-button {
  width: 225px;
  border: 1px solid #fff;
  border-right-width: 2px;
  border-bottom-width: 2px;
}

.royal-button:hover {
  border-color: #d6ad3d;
}

.royal-button::after {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
  {
  opacity: 0;
}
</style>
