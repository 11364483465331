<template>
  <b-overlay :show="show" fixed no-wrap variant="dark" z-index="11" @click="$emit('close')">
    <template #overlay>
      <div class="request-body" :class="{ dark }">
        <h3>Реквизиты</h3>
        <p data-v-13de8022="" class="black--text">
          Наименование организации (полное): Общество с ограниченной ответственностью «Роял Тревел»
        </p>
        <p data-v-13de8022="" class="black--text">
          Наименование организации (сокращенное): ООО «Роял Тревел»
        </p>
        <p data-v-13de8022="" class="black--text">
          Организационно-правовая форма: Общество с ограниченной ответственностью
        </p>
        <p data-v-13de8022="" class="black--text">
          Лист о государственной регистрации юр лица, ОГРН: от 03 марта 2021 года, ОГРН 1213200000870
        </p>
        <p data-v-13de8022="" class="black--text">
          Свидетельство о постановке на налоговый учет (кем выдано), ИНН / КПП: от 03 марта 2021 года, Инспекция
          Федеральной налоговой службы по г. Брянску ИНН 3257078251 КПП 325701001
        </p>
        <p data-v-13de8022="" class="black--text">
          ОКПО: 47403923
        </p>
        <p data-v-13de8022="" class="black--text">
          Банковские реквизиты: р/с 40702810310660001151 в Филиал "Центрального" Банка ВТБ(ПАО) к/с 30101810145250000411
          БИК 044525411
        </p>
        <p data-v-13de8022="" class="black--text">
          Применяемый режим налогообложения: Упрощенная система налогообложения
        </p>
        <p data-v-13de8022="" class="black--text">
          Юридический адрес (с индексом): 241050, РОССИЯ, БРЯНСКАЯ ОБЛ., ГОРОД БРЯНСК Г.О., БРЯНСК Г., 7-Я ЛИНИЯ УЛ., Д.
          42, ОФИС 31
        </p>
        <p data-v-13de8022="" class="black--text">
          Фактический адрес (с индексом): 241050, РОССИЯ, БРЯНСКАЯ ОБЛ., ГОРОД БРЯНСК Г.О., БРЯНСК Г., 7-Я ЛИНИЯ УЛ., Д.
          42, ОФИС 31
        </p>
        <p data-v-13de8022="" class="black--text">
          Почтовый адрес (с индексом): 241050, РОССИЯ, БРЯНСКАЯ ОБЛ., ГОРОД БРЯНСК Г.О., БРЯНСК Г., 7-Я ЛИНИЯ УЛ., Д. 42,
          ОФИС 31
        </p>
        <p data-v-13de8022="" class="black--text">
          Должность руководителя (Ф.И.О., контактный телефон сотовый): Генеральный директор Прудникова Оксана Николаевна
        </p>
        <p data-v-13de8022="" class="black--text">
          Контактный телефон / факс (организации): +7 930 820-09-09
        </p>
        <p data-v-13de8022="" class="black--text">
          Адрес электронной почты: royal_travels@inbox.ru </p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dark: false,
    }
  }
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dark-button {
  position: absolute;
  top: 10px;
  left: 10px;
}

.request-body {
  width: 800px;
  box-shadow: inset -6px -6px 0 #d6ad3d, inset 3px 3px 0 #d6ad3d;
  background: #fff;
  padding: 20px;
  position: relative;
  max-width: 90vw;
}

.request-form {
  margin: auto;
}

.request-header {
  color: #000;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
}

.form * {
  font-family: "Cormorant Garamond", serif;
}

.request-body.dark {
  background: transparent;
}

.request-body.dark .request-header {
  color: #fff;
}

.submit-btn {
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-size: 20px !important;
}

@media screen and (max-width: 800px) {
  p {
    font-size: 10px;
  }
}
</style>
<style>
.v-label {
  text-transform: uppercase;
  font-weight: 600;
}

.request-form input,
.request-form textarea {
  font-size: 20px !important;
  font-family: "Philosopher", sans-serif;
}

.v-messages__wrapper {
  font-size: 16px;
  font-weight: 600;
}
</style>
