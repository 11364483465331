<template>
  <b-overlay :show="show" fixed no-wrap variant="dark" z-index="11" @click="$emit('close')">
    <template #overlay>
      <div class="request-body" :class="{ dark }">
        <h3>Сертификаты</h3>
        <img :src="Sertificate" class="dialog-img" />
      </div>
    </template>
  </b-overlay>
</template>

<script>
import Sertificate from "@/assets/img/sertificate.jpg"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,

    }
  },
  data() {
    return {
      dark: false,
      Sertificate
    }
  },
  mounted() {
    console.log(Sertificate)
  }
};
</script>

<style scoped>
.dialog-img {
  max-width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dark-button {
  position: absolute;
  top: 10px;
  left: 10px;
}

.request-body {
  scroll-behavior: smooth;
  max-height: 80vh;
  width: 800px;
  overflow: auto;
  scrollbar-width: 0;
  scrollbar-color: rgba(0, 0, 0, 0);
  max-width: 800px;
  box-shadow: inset -6px -6px 0 #d6ad3d, inset 3px 3px 0 #d6ad3d;
  background: #fff;
  padding: 20px;
  position: relative;
  max-width: 90vw;
  overflow: scroll;
}

.request-form {
  margin: auto;
}

.request-header {
  color: #000;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
}

.form * {
  font-family: "Cormorant Garamond", serif;
}

.request-body.dark {
  background: transparent;
}

.request-body.dark .request-header {
  color: #fff;
}

.submit-btn {
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-size: 20px !important;
}
</style>
<style>
.v-label {
  text-transform: uppercase;
  font-weight: 600;
}

.request-form input,
.request-form textarea {
  font-size: 20px !important;
  font-family: "Philosopher", sans-serif;
}

.v-messages__wrapper {
  font-size: 16px;
  font-weight: 600;
}
</style>
