<template>
  <div class="royal-footer">
    <div class="container">
      <div class="head">
        <div class="logo">Royal Travel</div>
        <div class="contacts">
          <span>Связаться с нами</span>
          <div class="icons">
            <a href="mailto: royal_travels@inbox.ru"><v-icon>mdi-email</v-icon></a>
            <a href="tel:+79308200909"><v-icon>mdi-phone</v-icon></a>
            <a target="_blank" href="https://api.whatsapp.com/send/?phone=79308200909">
              <v-icon>mdi-whatsapp</v-icon>
            </a>
            <a target="_blank" href="vk.com"><v-icon>mdi-vk</v-icon></a>
          </div>
        </div>
      </div>
      <div class="body">
        © 2021, Royal Travel,
        <a target="_blank" href="mailto: netherlander01@gmail.com" class="royal-text">Made by KA</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.royal-footer {
  background: #212121;
  padding: 100px 0;
  color: #fff;
}

.royal-footer .logo {
  font-size: 32px;
  font-weight: 200;
  font-family: "Cormorant Garamond", serif;
  text-transform: uppercase;
}

.royal-footer * {
  color: #fff;
  font-family: "Philosopher", sans-serif;
}

.royal-footer .head {
  display: flex;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}

.icons {
  display: flex;
  justify-content: space-between;
}

.royal-footer .body {
  padding: 20px 0;
  font-size: 14px;
}

.royal-text {
  color: #f7c745;
  text-decoration: none;
}
</style>
