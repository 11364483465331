import swiper1 from "@/assets/img/swiper1.jpg";
import swiper2 from "@/assets/img/swiper2.jpg";
import swiper3 from "@/assets/img/swiper3.jpg";
import swiper4 from "@/assets/img/swiper4.jpg";
import swiper5 from "@/assets/img/swiper5.jpg";
import swiper6 from "@/assets/img/swiper6.jpg";
import swiper7 from "@/assets/img/swiper7.jpg";
import swiper8 from "@/assets/img/swiper8.jpg";
import swiper9 from "@/assets/img/swiper9.jpg";
import swiper10 from "@/assets/img/swiper10.jpg";
export default [
  {
    country: 'Россия',
    description: `Самый простой вариант для тех, кто хочет поближе и поспокойней. Курорты Крыма и Краснодарского края второй год переживают ренессанс на фоне нестабильной работы заграничных курортов.`,
    img: swiper1,
  },
  {
    country: 'Турция',
    description: `Курортный сезон в Турции растянется до ноября. В первую половину осени погода будет комфортной для пляжного отдыха практически во всех регионах страны. А тех, кому море покажется холодным, спасут отели с теплыми бассейнами. Традиционно самый холодный турецкий город — это Стамбул, к ноябрю температура воздуха упадет до +16 градусов (и это всяко лучше, чем в это же время в России).`,
    img: swiper2,
  },
  {
    country: 'Египет',
    description: `С 9 августа Россия и Египет восстановили регулярное сообщение после почти шестилетнего перерыва. Самолеты летают в Хургаду и Шарм-эш-Шейх, правда пока только из Москвы. Но со временем, надеются обе стороны, рейсов будет больше, а вместе с ними пойдет и рост предложений от туроператоров.`,
    img: swiper3,
  },
  {
    country: 'Греция',
    description: `В сентябре в Греции бархатный сезон: туристов уже не так много, а погода по-прежнему теплая (на южных островах прогревается до 28–30 градусов). Возможны дожди, но непродолжительные. Для отдыха туристы советуют выбирать остров Родос или Крит — там теплее, да и летают туда почти все российские авиакомпании.`,
    img: swiper4,
  },
  {
    country: 'Куба',
    description: `Идеальное время для отдыха на Кубе — вторая половина осени, причем чем позже, тем лучше. В октябре на Кубе заканчивается сезон дождей, пронизывающих ветров и ураганов. Температура на Варадеро и Гаване подтягивается до 30 градусов, море по-прежнему теплое. В ноябре спадает духота, количество осадков сокращается. Экскурсии можно чередовать с дайвингом и безмятежным валянием на пляже. `,
    img: swiper5,
  },
  {
    country: 'ОАЭ',
    description: `Этот вариант можно рассматривать ближе к ноябрю, потому что до этого времени почти во всех эмиратах царят бесчеловечные +36... +38 градусов тепла. В ноябре столбик термометра опускается до +30 градусов, и пляжный отдых становится комфортным. Можно никуда не уходить с территории отеля, меняя лежак на море и обратно, а можно податься в экскурсионный трип — можно не рассказывать про сумасшедшие дубайские небоскребы, сказочные цветочные сады и парки аттракционов, торговые центры и прочее.`,
    img: swiper6,
  },
  {
    country: 'Мексика',
    description: `Купаться на курортах Мексики можно круглый год. Температура воздуха и воды в течение года меняются незначительно. Более значимо оказывает влияние на комфорт уровень влажности и осадков. Особенностью осени является то, что в это время заканчивается сезон дождей. Примерно со второй половины октября вероятность ураганов и ливней значительно уменьшается, а в ноябре происходит открытие сухого “туристического  сезона”. Средняя температура воздуха на самом популярном курорте Канкун в ноябре +29 °C, воды +28 °C.`,
    img: swiper7,
  },
  {
    country: 'Мальдивы',
    description: `Летом на Мальдивах сезон дождей, а вот осенью дожди отступают. Хоть температура и не меняется (воздух  всегда примерно +30 °C, вода +28 °C), осенние месяцы имеют свои особенности. В сентябре дожди еще продолжаются, сильных штормов уже нет, но ветер и волны в океане еще бывают (любителям серфинга должно понравится). Примерно с 20-х чисел сентября океан начинает успокаиваться.`,
    img: swiper8,
  },
  {
    country: 'Доминиканская республика',
    description: `Доминиканская Республика — благодатный край, раскинувшийся на востоке острова Гаити в окружении волн Карибского моря. Столица — знойный Санто-Доминго, родина эталонных сигар, колыбель зажигательной меренге.`,
    img: swiper9,
  },
  {
    country: 'Венесуэла',
    description: `Колоритная Венесуэла знаменита многочисленными Мисс Мира и бесконечными сериалами. Для туристов же тут имеются горные пейзажи Анд, курортный остров Маргарита и старинные города конкистадоров, водопады и национальные парки.`,
    img: swiper10,
  },
];