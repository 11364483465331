<template>
  <v-card tile elevation="0" class="country-card">
    <v-img :aspect-ratio="16 / 9" :src="img" class="country-card__img"></v-img>
    <v-card-subtitle class="country-card__country">{{
      country
    }}</v-card-subtitle>
    <v-card-text class="country-card__description">{{
      description
    }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    country: {
      type: String,
      default: "Страна"
    },
    description: {
      type: String,
      default:
        "Описание отеля. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur minima, aspernatur voluptates dicta vel quis rem modi similique optio laudantium!"
    },
    img: {
      type: String,
      default: "img/hotel1.jpg"
    }
  }
};
</script>

<style scoped>
.country-card {
  background: transparent !important;
  overflow: hidden;
  max-width: 95vw;
}

.country-card * {
  padding: 0;
  font-family: "Cormorant Garamond", serif;
}

.country-card__country {
  padding: 20px 0;
  font-size: 20px;
}

.country-card__hotel {
  font-size: 28px;
}

.country-card__country {
  font-family: "Philosopher", sans-serif;
}

.country-card__description {
  font-family: "Philosopher", sans-serif;
  font-size: 18px;
  text-align: justify;
}

.country-card__img {
  transition: 0.6s ease-in-out;
}

.country-card__img:hover {
  transform: scale(1.1);
}
</style>
