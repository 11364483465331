<template>
  <b-overlay :show="show" fixed no-wrap variant="dark" z-index="11">
    <template #overlay>
      <div class="request-body" :class="{ dark }">
        <v-btn icon class="close-button" @click="$emit('close')" tile :dark="dark"><v-icon>mdi-close</v-icon></v-btn>
        <v-btn icon class="dark-button" tile @click="dark = !dark" :dark="dark"><v-icon>{{
          !dark ? "mdi-moon-waning-crescent" : "mdi-weather-sunny"
        }}</v-icon></v-btn>
        <div class="request-form">
          <h3 class="request-header text-center">Подобрать тур</h3>
          <v-form class="form" ref="form">
            <v-row class="my-0">
              <v-col cols="12" md="6">
                <v-text-field filled label="Куда летим?" color="#d6ad3d" :dark="dark" v-model="form.from"
                  hide-details="auto" prepend-inner-icon="mdi-airplane" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field filled label="Откуда летим?" color="#d6ad3d" :dark="dark" v-model="form.to"
                  hide-details="auto" prepend-inner-icon="mdi-airplane" />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12">
                <v-text-field filled label="Когда летим?" color="#d6ad3d" :dark="dark" v-model="form.date"
                  hide-details="auto" prepend-inner-icon="mdi-calendar" disabled />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-date-picker :dark="dark" @input="computeDate()" v-model="date1" no-title
                class="col-12 col-md-6"></v-date-picker>
              <v-date-picker :dark="dark" @input="computeDate()" v-model="date2" no-title
                class="col-12 col-md-6"></v-date-picker>

            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6">
                <v-select :items="numbers" filled label="Сколько взрослых?" color="#d6ad3d" :dark="dark"
                  v-model="form.adults" hide-details="auto" />
              </v-col>
              <v-col cols="12" md="6">
                <v-select :items="numbers" filled label="Сколько детей?" color="#d6ad3d" :dark="dark"
                  v-model="form.children" hide-details="auto" />
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" v-for="i in form.children" :key="i">
                <v-text-field v-mask="'########'" filled :label="`Возраст ребенка`" color="#d6ad3d" :dark="dark"
                  v-model="form.childrenYears[i - 1]" hide-details="auto" />
              </v-col>

            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6">
                <v-text-field filled label="Бюджет до" color="#d6ad3d" :dark="dark" v-model="form.cash"
                  v-mask="'###################'" hide-details="auto" prepend-inner-icon="mdi-cash" suffix="р." />
              </v-col>
              <v-col cols="12" md="6">
                <v-select :items="stars" filled label="Класс отеля" color="#d6ad3d" :dark="dark" v-model="form.stars"
                  hide-details="auto" prepend-inner-icon="mdi-human-greeting">
                  <template #item="item">
                    <div v-for="i in item.item" :key="i">
                      <v-icon class="mdi-star mdi"></v-icon>

                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12">
                <v-textarea filled label="Ваши пожелания" color="#d6ad3d" :dark="dark" v-model="form.comment"
                  :rules="rules.comment" prepend-inner-icon="mdi-comment" />
              </v-col>
            </v-row>
            <v-text-field filled label="Ваше имя" color="#d6ad3d" :dark="dark" v-model="form.name" :rules="rules.name"
              prepend-inner-icon="mdi-human-greeting" />
            <v-text-field prepend-inner-icon="mdi-email" filled label="Email" v-model="form.email" :rules="rules.email"
              color="#d6ad3d" :dark="dark" />
            <v-text-field prepend-inner-icon="mdi-cellphone-iphone" filled label="Телефон" color="#d6ad3d"
              v-mask="'+7 (###) ###-##-##'" v-model="form.phone" :rules="rules.phone" :dark="dark" />
            <v-checkbox :dark="dark" color="#d6ad3d" label="Я согласен на обработку персональных данных"
              v-model="form.agree" :rules="rules.agree"></v-checkbox>
            <v-btn class="submit-btn" :dark="dark" tile block @click="submitForm">Оставить заявку</v-btn>
          </v-form>
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
      axios,
      dark: false,
      form: {
        from: "",
        to: "",
        date: "",
        adults: "",
        children: "",
        childrenYears: [],
        cash: "",
        stars: "",
        name: "",
        email: "",
        phone: "",
        comment: "",
        agree: false,
      },
      date1: "",
      date2: "",
      numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      stars: [5, 4, 3, 2],

      rules: {
        name: [
          (v) => !!v || "Имя не может быть пустым",
          (v) => v.length >= 4 || "Имя не может быть меньше 4 символов",
        ],
        email: [
          (v) => !!v || "E-mail не может быть пустым",
          (v) =>
            /.+@.+/.test(v) ||
            "Пожалуйста, введите правильную электронную почту",
        ],
        phone: [
          (v) => !!v || "Телефон не может быть пустым",
          (v) => v.length == 18 || "Пожалуйста, введите правильный телефон",
        ],
        comment: [(v) => !!v || "Пожалуйста, оставьте комментарий"],
        agree: [(v) => !!v || "Для продолжения необходимо согласие"],
      },
    };
  },

  methods: {
    async submitForm() {
      let validation = this.$refs.form.validate();
      if (validation) {
        this.axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        this.axios.defaults.headers.post['Content-Type'] = 'application/json';
        console.log(this.form)
        const post = await this.axios.post('https://api.royal-travel-32.ru/', JSON.stringify({ ...this.form }));
        if (post.status == 200) {
          this.$emit('close');
        }
      }
    },
    computeDate() {
      this.form.date = `с ${this.date1 ? moment(this.date1).format('DD.MM.YYYY') : ''} по ${this.date2 ? moment(this.date2).format('DD.MM.YYYY') : ''}`
    }
  },
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dark-button {
  position: absolute;
  top: 10px;
  left: 10px;
}

.request-body {
  width: 800px;
  box-shadow: inset -6px -6px 0 #d6ad3d, inset 3px 3px 0 #d6ad3d;
  background: #fff;
  padding: 20px;
  position: relative;
  max-width: 90vw;
  overflow: auto;
  max-height: 90vh;
}

.request-form {
  margin: auto;
}

.request-header {
  color: #000;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
}

.form * {
  font-family: "Cormorant Garamond", serif;
}

.request-body.dark {
  background: transparent;
}

.request-body.dark .request-header {
  color: #fff;
}

.submit-btn {
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-size: 20px !important;
}
</style>
<style>
.v-label {
  text-transform: uppercase;
  font-weight: 600;
}

.request-form input,
.request-form textarea {
  font-size: 20px !important;
  font-family: "Philosopher", sans-serif;
}

.v-messages__wrapper {
  font-size: 16px;
  font-weight: 600;
}
</style>
