<template>
  <v-app>
    <!-- <v-app-bar absolute> </v-app-bar> -->
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
  scroll-behavior: smooth;
}
html * {
  margin: 0;
  padding: 0;
}

.pointer {
  cursor: pointer;
}
section {
  height: 100vh;
}
</style>
