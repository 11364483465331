
import card4 from "@/assets/img/card4.jpg";
import card5 from "@/assets/img/card5.jpg";
import card6 from "@/assets/img/card6.jpg";

export default [
  {
    id: 1,
    title: "Удобство",
    text: `Заказать любые услуги, получить консультацию квалифицированного специалиста, ознакомиться с предлагаемыми турами Вы сможете не выходя из своего дома – удобный информационный сайт нашей компании всегда к Вашим услугам! `,
    img:
     card4
  },
  {
    id: 2,
    title: "Надежность",
    text: `Наличие надежных партнеров, налаженная компьютерная сеть, доступ к необходимым онлайн-сервисам позволяют нам получать важную информацию о самых выгодных предложениях, горящих путевках, сезонных скидках и новейших интересных маршрутах для того, чтобы сделать Ваше путешествие максимально соответствующим всем требованиям или даже намного лучше, чем Вы ожидали. `,
    img:
      card5
  },
  {
    id: 3,
    title: "Выбор",
    text: `В процессе планирования тура наши специалисты обязательно предложат различные варианты проживания и условия питания, заблаговременно решат все вопросы по трансферу и аренде помещений для всевозможных деловых мероприятий, предоставят всю информацию о посещаемых странах, их достопримечательностях и особенностях пребывания туристов. `,
    img:
      card6
  }
];