<template>
  <div class="home" id="main">
    <RequestForm :show="requestForm" @close="requestForm = false" />
    <Requisits :show="requisitsForm" @close="requisitsForm = false" />
    <Sertificates :show="sertificatesForm" @close="sertificatesForm = false" />

    <Header @request="requestForm = true" :mobile="mobile" />
    <div class="home-bg home-background">
      <video id="mainVideo" muted loop autoplay preload="auto">
        <source src="@/assets/video/waves.mp4" type="video/mp4" />
      </video>
      <h2 class="home-title" :class="`${mobile ? 'mobile' : ''}`">
        Путешествие в стиле
        <span class="royal-span">Royal</span>
      </h2>
    </div>
    <div class="home-main home-background-reverse" id="service">
      <div class="container">
        <div class="royal-text-block">
          <h3 class="royal-title">
            Сделайте первый шаг в мир
            <span class="royal-span">роскошных путешествий</span>
          </h3>
        </div>
        <div class="cards-block">
          <RoyalCard :card="card" v-for="card in adventureCards" :key="card.id" />
        </div>
      </div>
    </div>
    <div class="home-swiper home-background" id="collection">
      <div class="container">
        <div class="royal-text-block">
          <h3 class="royal-title">
            <span class="royal-span">Royal</span> коллекция
          </h3>
        </div>
        <swiper ref="mySwiper" :options="swiperOptions" style="width:100vw" class="swiper-slider">
          <swiper-slide v-for="swiperCard in swiperCards" :key="swiperCard.country">
            <CountryCard :country="swiperCard.country" :description="swiperCard.description" :img="swiperCard.img" />
          </swiper-slide>
        </swiper>
        <div class="swiper-buttons">
          <div class="swiper-button-prev"><v-icon>mdi-arrow-left</v-icon></div>
          <div class="swiper-button-next"><v-icon>mdi-arrow-right</v-icon></div>
        </div>
        <div class="swiper-scrollbar"></div>
      </div>
    </div>
    <div class="home-conditions home-background-reverse" id="conditions">
      <div class="container">
        <div class="royal-text-block">
          <h3 class="royal-title">
            <span class="royal-span">Royal</span> условия
          </h3>
          <p class="royal-text">
            Компания «Royal Travel» помогает открывать новые возможности и предоставляет большой пакет уникальных
            туристических услуг с высоким уровнем сервиса для клиентов с разным бюджетом. Сотрудничество с нашим агенством
            позволит Вам воплотить в реальность мечты об экзотических странах, прекрасных уголках природы, бескрайних
            океанских просторах и белоснежных горных вершинах. Вы сможете участвовать в интереснейших экскурсионных
            программах, посещать удивительные достопримечательности России и зарубежья, решать деловые вопросы, проводить
            различные мероприятия, беззаботно отдыхать, развлекаться и оздоровляться в разных регионах мира по приемлемым
            ценам.
          </p>
        </div>
        <div class="cards-block">
          <RoyalCard :card="card" v-for="card in servicesCards" :key="card.id" />
        </div>
      </div>
    </div>
    <section class="home-form home-background">
      <h3 class="royal-title text-center">Ждем вас</h3>
      <p class="royal-text text-center w-50">
        Компания Royal Travel сможет подобрать для вас незабываемое путешествие. От него вас отделяет лишь одно нажатие...
      </p>
      <button @click="requestForm = true" class="royal-link royal-button">
        Подобрать тур
      </button>
      <div class="row mt-3">
        <div class="col col-6">
          <v-btn @click="requisitsForm = true" outlined tile light> реквизиты</v-btn>
        </div>
        <div class="col col-6">
          <v-btn outlined tile light @click="sertificatesForm = true"> сертификаты</v-btn>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import CountryCard from "@/components/CountryCard.vue";
import RoyalCard from "@/components/RoyalCard.vue";
import RequestForm from "@/components/RequestForm.vue";
import Requisits from "@/components/Requisits.vue";
import Sertificates from "@/components/Sertificates.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import adventureCards from "@/store/adventureCards";
import servicesCards from "@/store/servicesCards";
import swiperCards from "@/store/swiperCards";
export default {
  components: {
    Header,
    RequestForm,
    RoyalCard,
    CountryCard,
    Footer,
    Requisits,
    Sertificates
  },
  data() {
    return {
      requestForm: false,
      requisitsForm: false,
      sertificatesForm: false,
      swiperCards: swiperCards,
      servicesCards: servicesCards,
      adventureCards: adventureCards,
      swiperOptions: {
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true,
          dragSize: 100
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 20,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 100
          }
        }

        // Some Swiper option/callback...
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    mobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        case 'lg': return false
        case 'xl': return false
        default: return false
      }
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    this.swiper.slideTo(1, 1000, false);
  }
};
</script>

<style scoped>
.home-background {
  background: rgb(240, 235, 229);
  background: linear-gradient(180deg,
      rgba(240, 235, 229, 1) 50%,
      rgba(217, 249, 255, 0.8) 100%);
}

.home-background-reverse {
  background: rgb(240, 235, 229);
  background: linear-gradient(0deg,
      rgba(240, 235, 229, 1) 50%,
      rgba(217, 249, 255, 0.8) 100%);
}

.home-bg {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mainVideo {
  position: absolute;
  z-index: -1;
  inset: 0;
}

.home-bg::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home-title {
  padding: 5px 20px;
  color: #fff;
  background: linear-gradient(135deg, rgba(0, 0, 0, .5), #0b4151);
  font-size: 70px;
  font-family: Cormorant Garamond, serif !important;
  position: relative;
  z-index: 2;
  font-weight: 300;
  opacity: 1;
}



.home-title .mobile {
  font-size: 40px !important;
}

.home-title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(180deg, #e6b93f 30%, rgba(230, 185, 63, 0));
  width: 4px;

}

.home-title::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background: linear-gradient(90deg, #e6b93f 50%, rgba(230, 185, 63, 0));
  width: 100%;
}

.home-main,
.home-conditions {
  padding: 100px 0;
  font-family: "Cormorant Garamond", serif;
}

.royal-span {
  color: #d4b972;
}

.royal-title {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 40px;
  line-height: 0.8;
}

.royal-text {
  text-align: justify;
  font-size: 18px;
  font-family: "Philosopher", sans-serif;
}

.cards-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cards-block>* {
  width: 30%;
}

.royal-text-block {
  width: 50%;
  margin-bottom: 50px;
}

.swiper-scrollbar {
  height: 5px;
  margin-top: 100px;
}

.home-swiper {
  padding: 100px 0;
}

.home-swiper .container {
  position: relative;
}

.swiper-buttons {
  position: absolute;
  width: 100px;
  height: 60px;
  border-radius: 999px;
  background: #fff;
  border: 1px solid lightgray;
  right: 0;
  z-index: 5;
  top: 50%;
  padding: 10px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.container.overflow-show {
  overflow-x: visible;
}

.home-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.royal-link {
  color: #000;
  text-decoration: none;
  transition: 0.3s ease-in;
  text-transform: uppercase;
  position: relative;
  font-size: 32px;
  font-weight: 200;
  word-break: unset;
  font-family: "Cormorant Garamond", serif;
}

.royal-link:hover {
  color: #aa8a31;
}

.royal-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background: #000;
  transition: 0.3s ease-in;
}

.royal-link:hover::after {
  background: #aa8a31;
  width: 100%;
}

.royal-button {
  padding: 20px 50px;
  border: 1px solid #000;
  border-right-width: 2px;
  border-bottom-width: 2px;
}

.royal-button:hover {
  border-color: #aa8a31;
}

.royal-button::after {
  opacity: 0;
}

@media screen and (max-width: 800px) {
  .cards-block {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .cards-block>* {
    width: 100%;
    margin: 20px 0;
  }

  .royal-text-block {
    width: 100%;
  }

  .home-title {
    font-size: 30px;
  }

  .home-bg {
    height: 40vh;

  }
}
</style>
