import card1 from "@/assets/img/card1.jpg";
import card2 from "@/assets/img/card2.jpg";
import card3 from "@/assets/img/card3.jpg";

export default [
  {
    id: 1,
    title: "Море, солнце, пляж",
    text: `Какая ассоциация возникает первой при слове «отдых»? Ну, пропуская, разумеется, возлежание на диване с бутылочкой пенной радости. Пляжный отдых. Ну конечно, отдых на пляже! Золотой песок, бесконечные голубые просторы моря и неба, полустертый бликующим в воде солнцем горизонт, пышная зеленая растительность, белые шезлонги и цветные коктейли… Рай земной. Куда же направить стопы свои, чтобы очутиться в этом раю? Вариантов, на самом деле, великое множество, вагон и маленькая тележка. Возможно, даже не одна. И выбирать можно было бы бесконечно, бездарно потратив весь свой драгоценный отпуск на бесцельные метания. Отправляясь на отдых в сторону одного из океанов, не забывайте об акклиматизации и обязательно закладывайте себе день-два на то, чтобы адаптироваться и не пасть жертвой синдрома Стендаля и солнечных ванн.`,
    img:card2
  },
  {
    id: 2,
    title: "Адреналин, спорт, экстрим",
    text: `Свои будни мы проводим в постоянной суете и раздражении. Ведь только так мы можем выжить, пытаясь угнаться за быстро убегающим временем. А долгожданный отдых кажется просто сказочной мечтой, которая может и не осуществиться, если вдруг случится очередной коллапс на работе. Вот и отдых при таком ритме должен быть стоящим. Пляжный расслабляющий или активный и экспрессивный отдых, тут выбор за вами. Но главное помните, что вам нужно восстановить все потраченные за год силы. Так что продумайте свой отдых с особой тщательностью. Если хотите уйти от повседневных будней, забыть обо всех проблемах, в общем, попасть в другой мир. Как насчет экстремального туризма? Да, именно его: с бурей эмоций, риском и незабываемыми впечатлениями. Вы просто посмотрите на привычный мир другими глазами, так, как не решились бы прежде. `,
    img: card1
  },
  {
    id: 3,
    title: "Экскурсии, места, воспоминания",
    text: `Вся соль экскурсионного туризма заключается в том, что он спокойно соседствует и с пляжным отдыхом, и с горнолыжным, как, впрочем, и с любым другим. Одно другое тут не исключает, скорее – дополняет. Однако существуют и целые туры, посвященные осмотру достопримечательностей городов и столиц, древностей и природных чудес всех пяти континентов. Такие путешествия выбирают обычно индивидуумы, женатые на искусстве или просто по-хорошему жадные до него, и люди, желающие на отдыхе посмотреть мир, а не только парочку райских, но совершенно одинаковых атоллов. Но, чтобы быть максимально честными самим с собой, необходимо всё же сказать о том, что «голая» экскурсионка вам большого удовольствия не доставит – в голове останется лишь беспорядочный коктейль из портиков да ротонд, десятков музейных залов и тысячелетних развалин. `,
    img:card3
  }
];