<template>
  <v-card class="royal-card" tile elevation="0">
    <v-img
      class="royal-card-img"
      :aspect-ratio="aspectRatio"
      :src="card.img"
      alt=""
    />
    <v-card-title class="royal-card-title">{{ card.title }}</v-card-title>
    <v-card-text class="royal-card-text">
      {{ card.text }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    aspectRatio() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 2 / 1;
        case "sm":
          return 2 / 1;
        case "md":
          return 9 / 10;
        case "lg":
          return 9 / 10;
        case "xl":
          return 9 / 10;
        default:
          return 9 / 10;
      }
    }
  }
};
</script>

<style scoped>
.royal-card {
  overflow-x: hidden;
  background: transparent;
}
.royal-card-img {
  width: auto;
}
.royal-card-title {
  margin: 15px 0;
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-size: 35px;
  padding: 0;
  word-break: keep-all;
}
.royal-card-text {
  font-family: "Philosopher", sans-serif;
  text-align: justify;
  padding: 0;
}
</style>
